<template>
  <div>
    <template v-if="item.link">
      <v-list-item :to="item.link" class="v-list-item">
        <v-list-item-action>
          <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
        </v-list-item-action>
        <v-list-item-title v-text="item.name" />
      </v-list-item>
    </template>
    <template v-else>
      <!-- With Childs -->
      <a href="#">
        <i :class="'fa ' + item.icon" />
        <span>{{ item.name }}</span>
        <span class="pull-right-container">
          <i class="fa fa-angle-left pull-right" />
        </span>
      </a>
      <ul v-if="item.items" class="treeview-menu">
        <sidebar-module v-for="(unit, index) in item.items" :key="index" :item="unit" />
      </ul>
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SidebarModule',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
</script>
